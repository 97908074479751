<template>
  <!--  <div class="top"> -->
  <div>
    <v-dialog v-model="shown" persistent>
      <v-card
        class="mx-auto px-6 py-8"
        :min-width="$vuetify.display.mobile ? '320px' : '420px'"
      >
        <v-form v-model="form" fast-fail @submit.prevent="onSubmit">
          <h3 class="title">{{ dialogTitle }}</h3>

          <v-text-field
            v-model="state.password"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            name="password"
            clearable
            :readonly="loading"
            placeholder="Mot de passe"
            :error-messages="v$.password.$errors.map((e) => e.$message)"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            @input="v$.password.$touch"
            @blur="v$.password.$touch"
          />

          <v-text-field
            v-model="state.passwordrepeat"
            :append-icon="showConfirmedPassword ? '$eye' : '$eyeOff'"
            name="passwordrepeat"
            :readonly="loading"
            clearable
            placeholder="Confirmation du mot de passe"
            :error-messages="v$.passwordrepeat.$errors.map((e) => e.$message)"
            :type="showConfirmedPassword ? 'text' : 'password'"
            @click:append="showConfirmedPassword = !showConfirmedPassword"
            @input="v$.passwordrepeat.$touch"
            @blur="v$.passwordrepeat.$touch"
          />

          <v-btn
            block
            :disabled="!form"
            :loading="loading"
            color="primary"
            transition="fade"
            size="large"
            name="submit"
            type="submit"
          >
            Envoyer
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
//import { captchalogin } from '../../../api/methods/Connect';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, sameAs, helpers } from '@vuelidate/validators';
import { reactive, onMounted, computed } from 'vue';
import { useNotifier } from 'vuetify-notifier';
import { useRouter, useRoute } from 'vue-router';

import { ref } from 'vue';
import log from 'loglevel';

const router = useRouter();
const route = useRoute();
const $notifier = useNotifier();

//import { captcharegister } from "../../api/methods/Connect";
//import VueRecaptcha from "vue-recaptcha";

let token = '';
let shown = ref(true);
let form = ref(null);
let loading = ref(false);
let submitStatus = ref('');
let state = reactive({
  password: '',
  passwordrepeat: ''
});
let showPassword = ref(false);
let showConfirmedPassword = ref(false);
let dialogTitle = ref('');

const rules = {
  password: {
    required,
    containsUppercase: helpers.withMessage(
      'Le mot de passe doit contenir au moins une majuscule',
      helpers.regex(/[A-Z]/)
    ),
    containsLowercase: helpers.withMessage(
      'Le mot de passe doit contenir au moins une minuscule',
      helpers.regex(/[a-z]/)
    ),
    containsNumber: helpers.withMessage(
      'Le mot de passe doit contenir au moins un chiffre',
      helpers.regex(/[0-9]/)
    ),
    containsSpecial: helpers.withMessage(
      'Le mot de passe doit contenir au moins un caractère spécial',
      helpers.regex(/[#?!@$%^&*-]/)
    ),
    minLength: minLength(10)
  },
  passwordrepeat: {
    required: helpers.withMessage('This field cannot be empty', required),
    sameAsPassword: helpers.withMessage(
      'les mots de passe doivent être identiques',
      sameAs(computed(() => state.password))
    )
  }
};

const v$ = useVuelidate(rules, state);

onMounted(() => {
  token = route.params.token;
  log.info('token: ', token);
  if (route.path.indexOf('enroll-account') > -1) {
    dialogTitle.value = 'Crée ton mot de passe';
  }
});

async function onSubmit(event) {
  event.preventDefault();

  v$.value.$touch();
  if (v$.value.$invalid) {
  } else {
    loading.value = true;

    Accounts.resetPassword(token, state.password, function (error) {
      if (error) {
        log.info('error: ');
        if (error.error == 403) {
          $notifier.alertError('Le lien a expiré');
        }
        log.info(error);
      } else {
        log.info('OK Register');

        setTimeout(() => {
          submitStatus.value = 'OK';

          router.push('/');
          // this.$router.push(path);
        }, 1000);
      }
    });
  }
}

/* Meteor.loginWithPassword(this.name, this.password, (error) => {
        if (error) {
          log.info(error);
        } else {
          log.info("Login : ");
          log.info(Meteor.userId);
          const path = `/activities`;
          if (this.$route.path !== path) this.$router.push(path);
          this.$router.push(path);
        }
        log.info("LOGIN");
      });
    }, */
</script>

<style lang="scss" scoped>
.v-overlay {
  background-image: url('/bg.png');
  background-size: cover;
}

h3.title {
  margin-bottom: 10px;
  font-size: 110%;
  font-weight: 800;
  text-align: center;
}
/* div.top {
  background: url('login.jpg');
} */

div.check {
  margin-top: -12px;
  padding: 0px 0px 0px 12px;
  color: red;
  font-size: 80%;
}
div.checkwhite {
  margin-top: -12px;
  color: #1e2833;
  font-size: 80%;
}
</style>
